<template>
  <div class="wrap">
    <div class="item itemBox" v-for="(item, index) in fileList" :key="index">
      <img :src="item.picture" />
      <div class="action">
        <img class="icon" src="@/assets/videoP2.png" @click="showVideo(item)" />
        <a-icon class="icon" type="delete" @click="del(index)" />
      </div>
    </div>
    <div
      class="plus itemBox"
      @click="visible = true"
      v-if="fileList.length < limit">
      <img src="@/assets/videoP.png" />
      <span>上传视频</span>
    </div>

    <!-- S modal 上传视频modal -->
    <a-modal
      width="600px"
      title="上传视频"
      v-model="visible"
      @ok="add"
      @cancel="cancel">
      <a-form-model
        ref="dishesForm"
        :model="dishesForm"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }">
        <a-form-model-item
          label="视频"
          prop="video"
          :rules="{
            required: true,
            validator: validator,
          }">
          <a-upload
            :fileList="dishesForm.video"
            :beforeUpload="beforeUpload"
            :customRequest="customRequest">
            <a-button>
              <a-icon :type="videoUploadLoading ? 'loading' : 'upload'" />
              上传视频
            </a-button>
          </a-upload>
        </a-form-model-item>

        <a-form-model-item
          label="视频展示图片"
          prop="picture"
          ref="dishesPicture"
          :rules="{
            required: true,
            message: '请上传视频展示图片',
          }">
          <img-uploader
            v-model="dishesForm.picture"
            @success="$refs.dishesPicture.onFieldBlur()"
            @remove="$refs.dishesPicture.onFieldBlur()"
          />
          <template #help>
            <div class="flex text-sm mb-4">
              <div class="text-theme-red mr-2">注:</div>
              <div>
                <p>1、格式必须为jpg/png</p>
                <p>2、建议尺寸：宽460px，高300px</p>
              </div>
            </div>
          </template>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- E modal 上传视频modal -->

    <!-- S modal 视频展示 -->
    <a-modal
      width="600px"
      title="视频预览"
      v-model="showVisible"
      :footer="null">
      <video muted id="videoElement" style="width: 100%; height: 300px"></video>
    </a-modal>
    <!-- E modal 视频展示 -->
  </div>
</template>

<script>
import { ossconfig } from '@/api'
import request from '@/utils/request'
import ImgUploader from '@/components/imgUploader'
import { checkDataType } from '@/utils/utils.js'
import { Modal } from 'ant-design-vue';
import flvjs from 'flv.js'

export default {
  components: { ImgUploader },
  props: {
    value: { type: [Array, Object, String], default: () => null },
    limit: { type: Number, default: 1 },
  },
  data() {
    return {
      valueType: '', // 传入的类型
      visible: false, // 上传modal状态
      // modal表单
      dishesForm: {
        video: [],
        picture: '',
      },
      errorCallback: null,
      videoUploadLoading: false,
      fileList: [], // 图片展示列表

      showVisible: false, // 视频展示modal
    }
  },
  created() {
    this.errorCallback = null
  },
  watch: {
    // 解析value
    value: {
      immediate: true,
      handler: function (_nVal, _oVal) {
        if (checkDataType(_nVal, 'array')) {
          this.fileList = [..._nVal]
          this.valueType = 'array'
        }
        if (checkDataType(_nVal, 'object')) {
          this.fileList = [{ ..._nVal }]
          this.valueType = 'object'
        }
        if (checkDataType(_nVal, 'string')) {
          this.fileList = []
          this.valueType = 'object'
        }
      },
    },
  },
  methods: {
    // cancel
    cancel() {
      this.dishesForm = {
        video: [],
        picture: '',
      }
      this.errorCallback = null
      this.$refs.dishesForm.clearValidate()
      if (this.valueType === 'array') this.$emit('input', this.fileList)
      else this.$emit('input', this.fileList[0])
    },

    // 新增
    add() {
      this.$nextTick(() => {
        if (this.dishesForm.video.length === 0) {
          this.$message.error('请上传视频')
        }
        if (this.dishesForm.picture && this.dishesForm.video.length !== 0) {
          this.fileList.push({
            picture: this.dishesForm.picture,
            video: this.dishesForm.video[0]?.url,
          })
          this.visible = false
          this.dishesForm = {
            video: [],
            picture: '',
          }
          this.$refs.dishesForm.clearValidate()

          if (this.valueType === 'array') this.$emit('input', this.fileList)
          else this.$emit('input', this.fileList[0])
        }
      })
    },

    // 删除
    del(index) {
      Modal.confirm({
        title: '提示',
        content: '确认要删除门店视频吗？',
        onOk: () => {
          this.fileList.splice(index, 1)
          this.$emit('remove')
        }
      })
    },

    // 视频预览
    showVideo(item) {
      this.showVisible = true
      this.$nextTick(() => {
        if (flvjs.isSupported()) {
          const videoElement = document.getElementById('videoElement')
          const flvPlayer = flvjs.createPlayer({
            type: item.video.split('.').pop(),
            url: item.video,
          })
          flvPlayer.attachMediaElement(videoElement)
          flvPlayer.load()
          flvPlayer.play()
        }
      })
    },

    // 文件上传前钩子函数
    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        const isJpgOrPng = ['mp4', 'flv'].includes(file.name.split('.')[1])
        if (isJpgOrPng) resolve()
        else {
          this.$message.warning('请上传mp4/flv格式的视频！')
          // eslint-disable-next-line prefer-promise-reject-errors
          reject()
        }
      })
    },

    // 覆盖默认上传函数
    async customRequest(file) {
      this.videoUploadLoading = true
      const {
        data: { url: ossUrl, multipart_params: multipartParams },
      } = await ossconfig()
      const formData = new FormData()
      const name = this.random_string(15) + this.formatFileName(file.file.name)
      Object.keys(multipartParams).forEach((index) => {
        if (index === 'key') {
          multipartParams.key += name
        }
        formData.append(index, multipartParams[index])
      })
      formData.append('file', file.file)
      formData.append('name', name)
      await request({ url: ossUrl, method: 'post', data: formData }).then(
        (res) => {
          this.dishesForm.video.push({
            name: res.data.filename,
            url: ossUrl + res.data.filename,
            status: 'done',
            uid: this.fileList.length + 1,
          })
        }
      )
      if (this.dishesForm.video.length === 0) {
        this.errorCallback && this.errorCallback('请上传视频')
      } else {
        this.errorCallback && this.errorCallback()
      }
      this.errorCallback = null
      this.videoUploadLoading = false
    },

    random_string(e) {
      e = e || 32
      const t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
      const a = t.length
      let n = ''
      for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a))
      return n
    },

    formatFileName(name) {
      const index = name.lastIndexOf('.')
      if (index !== -1) {
        return name.substring(index)
      } else {
        return ''
      }
    },

    // 视频校验钩子
    validator(rule, value, callback) {
      this.errorCallback = callback
    },
  },
}
</script>

<style scoped lang="less">
.wrap {
  width: 100%;
  overflow: hidden;
  display: flex;
  .itemBox {
    width: 141px;
    height: 141px;
    padding: 8px;
    margin: 0 8px 8px 0;
    border-radius: 2px;
  }
  .plus {
    background: #fafafa;
    border: 1px dashed #d9d9d9;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    img {
      width: 16px;
      height: 16px;
    }
    span {
      margin-top: 8px;
      text-align: center;
      color: rgba(0, 0, 0, 0.65);
      font-size: 16px;
      font-variant: tabular-nums;
      line-height: 1.5;
      box-sizing: border-box;
    }
  }

  .item {
    border: 1px solid #d9d9d9;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .action {
      opacity: 0;
      width: 100%;
      height: 100%;
      padding: 8px;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.5);
      background-clip: content-box;
      color: #fff;
      transition: opacity 0.3s;
      .icon {
        width: 16px;
        height: 16px;
        margin: 0 4px;
        cursor: pointer;
      }
    }

    .action:hover {
      opacity: 1;
    }
  }
}
</style>
