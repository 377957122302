var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_vm._l((_vm.fileList),function(item,index){return _c('div',{key:index,staticClass:"item itemBox"},[_c('img',{attrs:{"src":item.picture}}),_c('div',{staticClass:"action"},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/videoP2.png")},on:{"click":function($event){return _vm.showVideo(item)}}}),_c('a-icon',{staticClass:"icon",attrs:{"type":"delete"},on:{"click":function($event){return _vm.del(index)}}})],1)])}),(_vm.fileList.length < _vm.limit)?_c('div',{staticClass:"plus itemBox",on:{"click":function($event){_vm.visible = true}}},[_c('img',{attrs:{"src":require("@/assets/videoP.png")}}),_c('span',[_vm._v("上传视频")])]):_vm._e(),_c('a-modal',{attrs:{"width":"600px","title":"上传视频"},on:{"ok":_vm.add,"cancel":_vm.cancel},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('a-form-model',{ref:"dishesForm",attrs:{"model":_vm.dishesForm,"label-col":{ span: 6 },"wrapper-col":{ span: 16 }}},[_c('a-form-model-item',{attrs:{"label":"视频","prop":"video","rules":{
          required: true,
          validator: _vm.validator,
        }}},[_c('a-upload',{attrs:{"fileList":_vm.dishesForm.video,"beforeUpload":_vm.beforeUpload,"customRequest":_vm.customRequest}},[_c('a-button',[_c('a-icon',{attrs:{"type":_vm.videoUploadLoading ? 'loading' : 'upload'}}),_vm._v(" 上传视频 ")],1)],1)],1),_c('a-form-model-item',{ref:"dishesPicture",attrs:{"label":"视频展示图片","prop":"picture","rules":{
          required: true,
          message: '请上传视频展示图片',
        }},scopedSlots:_vm._u([{key:"help",fn:function(){return [_c('div',{staticClass:"flex text-sm mb-4"},[_c('div',{staticClass:"text-theme-red mr-2"},[_vm._v("注:")]),_c('div',[_c('p',[_vm._v("1、格式必须为jpg/png")]),_c('p',[_vm._v("2、建议尺寸：宽460px，高300px")])])])]},proxy:true}])},[_c('img-uploader',{on:{"success":function($event){return _vm.$refs.dishesPicture.onFieldBlur()},"remove":function($event){return _vm.$refs.dishesPicture.onFieldBlur()}},model:{value:(_vm.dishesForm.picture),callback:function ($$v) {_vm.$set(_vm.dishesForm, "picture", $$v)},expression:"dishesForm.picture"}})],1)],1)],1),_c('a-modal',{attrs:{"width":"600px","title":"视频预览","footer":null},model:{value:(_vm.showVisible),callback:function ($$v) {_vm.showVisible=$$v},expression:"showVisible"}},[_c('video',{staticStyle:{"width":"100%","height":"300px"},attrs:{"muted":"","id":"videoElement"},domProps:{"muted":true}})])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }