<template>
	<div style="border: 1px solid #ccc">
		<Toolbar
			style="border-bottom: 1px solid #ccc"
			:editor="editor"
			:defaultConfig="toolbarConfig"
			:mode="mode" />
		<a-spin size="large" :spinning="isSpinning">
			<Editor
				style="height: 600px; overflow-y: hidden"
				v-model="html"
				:defaultConfig="editorConfig"
				:mode="mode"
				@onCreated="onCreated"
				@onChange="onChange" />
		</a-spin>
	</div>
</template>

<script>
import request from '@/utils/request'
import { ossconfig } from '@/api'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default {
	name: 'WangEditor',
	components: { Editor, Toolbar },
	props: {
		id: {
			type: String,
			default: 'editor'
		},
		value: {
			type: String,
			default: ''
		}
	},
	data() {
		// 自定义插入图片/视频
		const handleUpLoad = async (file, insertFn) => {
			this.isSpinning = true
			// 限制图片大小，最大不能超过10M
			if (
				file.type &&
				file.type.includes('image') &&
				file.size &&
				file.size > 10 * 1024 * 1024
			) {
				this.isSpinning = false
				this.$message.error('图片大小超出10M限制！')
				return
			}
			const {
				data: {
					url: ossUrl,
					cdn_url,
					multipart_params: multipartParams
				}
			} = await ossconfig()
			const formData = new FormData()
			const name = random_string(15) + formatFileName(file.name)
			Object.keys(multipartParams).forEach(index => {
				if (index === 'key') {
					multipartParams.key += name
				}
				formData.append(index, multipartParams[index])
			})
			formData.append('file', file)
			formData.append('name', name)
			const { data } = await request({
				url: ossUrl,
				method: 'post',
				data: formData
			})
			// 插入图片/视频
			insertFn(cdn_url + data.filename, '', '')
			this.isSpinning = false
		}
		//  生成随机文件名
		const random_string = e => {
			e = e || 32
			const t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
			const a = t.length
			let n = ''
			for (let i = 0; i < e; i++)
				n += t.charAt(Math.floor(Math.random() * a))
			return n
		}
		// 格式化文件名
		const formatFileName = name => {
			const index = name.lastIndexOf('.')
			if (index !== -1) {
				return name.substring(index)
			} else {
				return ''
			}
		}
		//上传的配置
		const editorConfig = {
			placeholder: '请输入内容...',
			MENU_CONF: {
				uploadImage: {
					//上传图片配置
					customUpload: handleUpLoad
				},
				uploadVideo: {
					//上传视频配置
					customUpload: handleUpLoad
				}
			}
		}
		return {
			isSpinning: false,
			// 富文本编辑器
			editor: null,
			// 富文本内容
			html: null,
			// 工具栏配置
			toolbarConfig: {
				// [ /* 显示哪些菜单，如何排序、分组 */ ]
				toolbarKeys: [
					'headerSelect',
					'bold',
					'underline',
					'italic',
					'fontSize',
					'lineHeight',
					'bulletedList',
					'numberedList',
					'justifyLeft',
					'justifyRight',
					'justifyCenter',
					'divider',
					'emotion',
					{
						key: 'group-image',
						title: '图片',
						iconSvg:
							'<svg viewBox="0 0 1024 1024"><path d="M959.877 128l0.123 0.123v767.775l-0.123 0.122H64.102l-0.122-0.122V128.123l0.122-0.123h895.775zM960 64H64C28.795 64 0 92.795 0 128v768c0 35.205 28.795 64 64 64h896c35.205 0 64-28.795 64-64V128c0-35.205-28.795-64-64-64zM832 288.01c0 53.023-42.988 96.01-96.01 96.01s-96.01-42.987-96.01-96.01S682.967 192 735.99 192 832 234.988 832 288.01zM896 832H128V704l224.01-384 256 320h64l224.01-192z"></path></svg>',
						menuKeys: ['uploadImage']
					},
					'uploadVideo'
				],
				// excludeKeys: [ /* 隐藏哪些菜单 */ ]
				excludeKeys: []
			},
			// 编辑器配置
			editorConfig: editorConfig,
			mode: 'default'
		}
	},
	methods: {
		/**
		 * @description: 实例化富文本编辑器
		 * @return {*}
		 * @param {*} editor
		 */
		onCreated(editor) {
			this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
		},
		/**
		 * @description: 监听富文本编辑器
		 * @return {*}
		 * @param {*} editor
		 */
		onChange(editor) {
			let data = editor.getText()
			let html = editor.getHtml()
			// 空内容且有标签下清空标签
			// 单上传图片或视频也会出现data为空，html不为空的情况
			if (
				html &&
				!data &&
				!html.includes('img') &&
				!html.includes('video')
			)
				html = ''
			this.$emit('change', html)
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.html = this.value
		})
	},
	beforeDestroy() {
		const editor = this.editor
		if (editor == null) return
		editor.destroy() // 组件销毁时，及时销毁编辑器
	}
}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less">
::v-deep .ant-spin-spinning {
	padding: 20px;
}
</style>
